import image from '../images/lakes.jpg';
import p1 from '../images/p1.jpg';
import p2 from '../images/p2.jpg';
import p3 from '../images/p3.jpg';
import p4 from '../images/p4.jpg';
import p5 from '../images/p5.jpg';
import p6 from '../images/p6.jpg';

 const tileData = [
   {
     img: p1,
     title: 'p1',
     author: 'author',
     cols: 1,
   },
   {
    img: p2,
    title: 'Image1',
    author: 'author',
    cols: 1,
  },
  {
    img: p3,
    title: 'Image2',
    author: 'author',
    cols: 1,
  },
  {
    img: p4,
    title: 'Image3',
    author: 'author',
    cols: 1,
  },
  {
    img: p5,
    title: 'Image3',
    author: 'author',
    cols: 1,
  },
  {
    img: p6,
    title: 'Image3',
    author: 'author',
    cols: 1,
  },
 ]

 export default tileData