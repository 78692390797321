import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LavaLamp from "../components/lavalamp/LavaLamp"
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { makeStyles } from '@material-ui/core/styles';
import tileData from '../other/tileData';

import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Fade from '@material-ui/core/Fade';


const GamePage = () => {

  const classes = useStyles();
  return (
<><LavaLamp pos = "2"></LavaLamp>

<Layout>
  <SEO title="Page two" />
  <h4>Just math 24</h4>
  <div className={classes.root}>
    <GridList cellHeight={160} className={classes.gridList} cols={2}>
      {tileData.map(tile => (
        <GridListTile key={tile.img} cols={tile.cols || 1}>
          <img src={tile.img} alt={tile.title} />
        </GridListTile>
      ))}
    </GridList>
</div>
</Layout></>


)
      }

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    maxWidth: 1500,
    maxHeight: 1500,
  },
  }));

  

export default GamePage
